import React from "react";
import insta from "../assets/images/insta.png";
import fb from "../assets/images/fb.png";
import snap from "../assets/images/snap.png";
import ytube from "../assets/images/Ytube.png";
import ws from "../assets/images/Wp.png";
import footerLogo from "../assets/images/Hsc.png";
import { Link } from "react-router-dom";
import { PlayArrow } from "@mui/icons-material";
import play from "../assets/images/google-play 1.png";
import { Button, Col, Container, Row } from "reactstrap";
const Footer = () => {
  return (
    <div className="container my-5">
      <footer>
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3">
            <div className="col-md-3 col-lg-2 col-xs-12 mx-auto mb-4">
              <a href="">
                <img src={footerLogo} height="50" className="pe-2" alt="Footer Logo" />
                <span className="fs-20 primaryColor fw-500 my-2">Hindu Shadi Connect</span>
              </a>
              <hr
                className="mb-4 mt-0 d-inline-block mx-auto"
                style={{ width: '60px', backgroundColor: '#7c4dff', height: '2px' }}
              />

              <p className="m-0 fs-16">
                <a href="tel:+9009992462">+91-9009992462</a>
              </p>
              <p className="m-0 fs-16">
                <a href="tel:+9009992464">+91-9009992464</a>
              </p>
              <p className="m-0 fs-16">
                <a href="mailto:info@hindushadiconnect.com">iinfo@hindushadiconnect.com</a>
              </p>
              <p className="m-0 fs-16">Civil Ward NO 10, In Front Of Agrawal School, Teen Gulli Chouraha,</p>
              <p className="mb-3 fs-16">Damoh, Madhya Pradesh - 470661</p>
              <div className={`d-flex justify-content-${window.innerWidth <= 767 ? 'center' : 'start'}`}>
                <a href="https://www.instagram.com/hindu.shadi.connect/" className="me-2">
                  <img src={insta} alt="Instagram" />
                </a>
                <a href="https://www.facebook.com/profile.php?id=61557317483628" className="me-2">
                  <img src={fb} alt="Facebook" />
                </a>
                <a href="https://www.youtube.com/channel/UCcY96r5QO676gtqbowb4XHw" className="me-2">
                  <img src={ytube} alt="YouTube" />
                </a>
                <a href="https://wa.me/9009992464" className="me-2">
                  <img src={ws} alt="WhatsApp" />
                </a>
                <a href="https://mobile.jainshadimilan.com/users/hindu-connect.apk">
                  <Button>
                    <img src={play} alt="Download" />
                    Download
                  </Button>
                </a>
              </div>
            </div>
            <div className="col-md-3 col-lg-2 col-xs-12 mx-auto mb-4">
              <h6 className="fs-20 primaryColor fw-500 my-2">HSC</h6>
              <hr
                className="mb-4 mt-0 d-inline-block mx-auto"
                style={{ width: '60px', backgroundColor: '#7c4dff', height: '2px' }}
              />
              <Link to="/about">
                <p className="fs-16">About Us</p>
              </Link>
            </div>
            <div className="col-md-3 col-lg-2 col-xs-12 mx-auto mb-4">
              <h6 className="fs-20 primaryColor fw-500 my-2">Privacy & Help</h6>
              <hr
                className="mb-4 mt-0 d-inline-block mx-auto"
                style={{ width: '60px', backgroundColor: '#7c4dff', height: '2px' }}
              />
              <Link to="/terms">
                <p className="fs-16">Terms & Conditions</p>
              </Link>
              <Link to="/registration">  <p className="fs-16">Registration Fees</p> </Link>
              <Link to="/refund-policy">
                <p className="fs-16">Refund & Cancellation Policy</p>
              </Link>
            </div>

            <div className="col-md-3 col-lg-2 col-xs-12 mx-auto mb-4">
              <h6 className="fs-20 primaryColor fw-500 my-2">Sign Up / Sign In</h6>
              <hr
                className="mb-4 mt-0 d-inline-block mx-auto"
                style={{ width: '60px', backgroundColor: '#7c4dff', height: '2px' }}
              />
              <Link to="/freeregistration"> <p className="fs-16">Free registration</p> </Link>
              <Link to="/member-login">
                <p className="fs-16">Member login</p>
              </Link>
              <Link to="/live-support">
                <p className="fs-16">24/7 Live support</p>
              </Link>
            </div>

            <div className="col-md-3 col-lg-2 col-xs-12 mx-auto mb-md-0 mb-4">
              <h6 className="fs-20 primaryColor fw-500 my-2">Help</h6>
              <hr
                className="mb-4 mt-0 d-inline-block mx-auto"
                style={{ width: '60px', backgroundColor: '#7c4dff', height: '2px' }}
              />
              <Link to="/contact">
                <p className="fs-16">Contact Us</p>
              </Link>

              <Link to="/privacy-policy">
                <p className="fs-16">Privacy Policy</p>
              </Link>
              <Link to="/terms">
                <p className="fs-16">Terms & Conditions</p>
              </Link>
            </div>
          </div>
        </div>

        <hr className="mt-3" />
        <p
          className={`m-0 ${window.innerWidth <= 767 ? 'text-center' : 'ps-5'}`}
          style={{ fontSize: '12px' }}
        >
          All rights reserved©2024{' '}
          <a href="https://hindushadiconnect.com/">hindushadiconnect.com</a> | This website is strictly for
          matrimonial purpose only and not a dating Website.
        </p>
      </footer>
    </div>
  );
};

export default Footer;
