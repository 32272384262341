import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Container, Row } from "reactstrap";

const FreeRegistration = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container fluid>
            <Row>
                <Header />
                <div className="topDivider">
                    <p className={`PageTitleText light p-2 m-0 ${window.innerWidth <= 768 ? 'fs-24' : 'fs-30'}`}>FreeRegistration</p>
                </div>
            </Row>
            <Row>
                <div className={`py-3 fs-16 ${window.innerWidth <= 768 ? 'px-3 text-center' : 'px-5'}`} style={{ borderBottom: "1px solid #d1d1d1" }}>
                    <p style={{ fontWeight: 'bold', color: 'black' }}>Join Hindu Shadi connect – Free Registration!</p>
                    <p>We believe in providing equal opportunities to everyone in
                        the Hindu community to find their perfect life partner.
                        That's why we offer free registration to get you started on
                        your journey to finding a meaningful relationship.
                    </p>

                    <p style={{ fontWeight: 'bold', color: 'black' }}>Why Register for Free?</p>


                    <p><span style={{ fontWeight: 'bold', color: 'black' }}>Easy Sign-Up:</span>   - Create your account in just a few
                        minutes.

                    </p>
                    <p><span style={{ fontWeight: 'bold', color: 'black' }}>Access to Verified Profiles:</span>   Explore a wide range of
                        authentic profiles from the Jain community.
                    </p>
                    <p><span style={{ fontWeight: 'bold', color: 'black' }}>Secure and Safe Platform:</span>   Your privacy and data are
                        protected with top-notch security
                    </p>
                    <h3 style={{ fontWeight: 'bold', color: 'black' }}>How to Register for Free?</h3>
                    <p>Fill out the registration form with your basic details.
                        Verify your account via email or phone number.
                        Start browsing profiles and connect with potential
                        matches</p>



                    <h4 className="py-3">- Hindu Shadi Connect</h4>
                </div >
                <Footer />
            </Row>

        </Container >
    )


};

export default FreeRegistration;
