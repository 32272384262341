import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Container, Row } from "reactstrap";

const Registration = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container fluid>
            <Row>
                <Header />
                <div className="topDivider">
                    <p className={`PageTitleText light p-2 m-0 ${window.innerWidth <= 768 ? 'fs-24' : 'fs-30'}`}>Registration</p>
                </div>
            </Row>
            <Row>
                <div className={`py-3 fs-16 ${window.innerWidth <= 768 ? 'px-3 text-center' : 'px-5'}`} style={{ borderBottom: "1px solid #d1d1d1" }}>
                    <p style={{ fontWeight: 'bold', color: 'black' }}>Start Your Journey with Hindu Shadi Connect.</p>
                    <p>To provide you with the best matchmaking experience, we
                        have a small registration fee. This fee helps us maintain a
                        high-quality platform with verified profiles and advanced
                        features.
                    </p>

                    <p style={{ fontWeight: 'bold', color: 'black' }}>Our Plans</p>
                    <p><span style={{ fontWeight: 'bold', color: 'black' }}>Silver:</span>  ₹500 for 3 month</p>
                    <p><span style={{ fontWeight: 'bold', color: 'black' }}>Gold:</span>  ₹751 For 6 Month</p>
                    <p><span style={{ fontWeight: 'bold', color: 'black' }}>Platinum:</span>   ₹ 1100
                        One-time payment for till marriage</p>
                    <p style={{ fontWeight: 'bold', color: 'black' }}>Why Do We Charge a Fee?</p>
                    <p><span style={{ fontWeight: 'bold', color: 'black' }}>Serious Users:</span>   Only genuine people join, ensuring a
                        trustworthy platform.
                    </p>
                    <p><span style={{ fontWeight: 'bold', color: 'black' }}>Secure Experience:</span>   - Helps us protect your data and
                        provide smooth services.
                    </p>
                    <p><span style={{ fontWeight: 'bold', color: 'black' }}>HelplineTeam:</span>   Our dedicated helpline team is here to
                        resolve your Queries and guide you every step of the way
                        Call us.
                    </p>
                    <h3 style={{ fontWeight: 'bold', color: 'black' }}>Important Notes:</h3>
                    <ul>
                        <li>• Payment is 100% secure.</li>
                        <li>• Fees are non-refundable.</li>
                        <li>• Join today and take the first step toward finding your life partner.</li>                    </ul>



                    <h4 className="py-3">- Hindu Shadi Connect</h4>
                </div >
                <Footer />
            </Row>

        </Container >
    )


};

export default Registration;
