import React, { useEffect, useRef, useState } from "react";
import { Container, Row } from "reactstrap";
import Header from "./Header";
import Footer from "./Footer";
import footerLogo from "../assets/images/Hsc.png";

const DownloadApp = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [downloadSpeed, setDownloadSpeed] = useState(0);
  const abortController = useRef(new AbortController());

  const downloadUrl =
    "https://mobile.jainshadimilan.com/users/hindu-connect.apk";

  const startDownload = async () => {
    setIsDownloading(true);
    setProgress(0);
    setDownloadSpeed(0);

    abortController.current = new AbortController(); // Create a new abort controller for each download attempt

    try {
      const response = await fetch(downloadUrl, {
        signal: abortController.current.signal,
      });
      const reader = response.body.getReader();
      const contentLength = +response.headers.get("Content-Length");

      let receivedLength = 0;
      const chunks = [];
      const startTime = Date.now();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunkTime = Date.now();
        chunks.push(value);
        receivedLength += value.length;

        const duration = (chunkTime - startTime) / 1000; // Time in seconds
        const speed = receivedLength / duration; // Bytes per second
        setDownloadSpeed(speed);

        setProgress(Math.floor((receivedLength / contentLength) * 100));
      }

      const blob = new Blob(chunks);
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "hindu-connect.apk";
      link.click();

      setIsDownloading(false);
      setProgress(100); // Set progress to 100% upon completion
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Download aborted");
      } else {
        console.error("Download failed", error);
      }
      setIsDownloading(false);
      setProgress(0); // Reset progress on error
    }
  };

  const cancelDownload = () => {
    abortController.current.abort();
  };

  return (
    <Container fluid>
      <Header />
      <div className="row justify-content-center py-3 download_app">
        <div className="col-lg-10">
          <div className="row vh-100 align-items-center">
            <div className="col-lg-6 col-md-6 col-12 text-center Downloadapp"></div>
            <div className="col-lg-6 col-md-6 col-12 text-center mx-auto">
              <img
                src={footerLogo}
                height="50"
                className="pe-2"
                alt="Footer Logo"
              />
              <span
                className={`text-black ${
                  window.innerWidth <= 768 ? "fs-5" : "fs-1"
                } d-block`}
                style={{ fontFamily: "Laila", fontWeight: "700" }}
              >
                Hindu Shadi Connect
              </span>
              <p className="text-black" style={{ fontWeight: "500" }}>
                we are thrilled to share with you the exciting news about the
                launch of "Hindu Shadi Connect." this exceptional platform has
                been created with the primary aim of helping Hindu young men and
                women discover their perfect life partner.
              </p>
              <div className=" button-27">
                <button className="btn-27"
                  onClick={isDownloading ? cancelDownload : startDownload}
                >
                  {isDownloading ? "Cancel" : "Download"}
                </button>
                {isDownloading && (
                  <div className="circle-progress">
                    <div className="progress-text">{`${progress}%`}</div>
                  </div>
                )}
                {!isDownloading && progress === 100 && (
                  <div>{`Download completed: ${progress}%`}</div>
                )}
               
              </div>
              {isDownloading && (
                  <div className="speed-text">
                    {`Speed: ${(downloadSpeed / 1024).toFixed(2)} KB/s`}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
};

export default DownloadApp;
